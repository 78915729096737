import React from "react";
import "./confirmOrderModal.css";

const ConfirmOrderModal = ({ isOpen, onConfirm, onCancel, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-actions">
          <button className="confirm-btn" onClick={onConfirm}>
            Yes it is, please continue to payment
          </button>
          <button className="cancel-btn" onClick={onCancel}>
            No, take me back to the calendar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrderModal;
