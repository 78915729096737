import React, { useMemo, useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { getAuth } from "firebase/auth";
import toast from "react-hot-toast";
import { jsPDF } from "jspdf";

const ClosedTable = ({ fetchTotals }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [orders, setOrders] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt"); // Default sorting by date ordered
  const [sortOrder, setSortOrder] = useState("desc"); // Default ascending order

  const fetchClosedOrders = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/api/orders/list?closed=true&page=${
          pageIndex + 1
        }&sortBy=${sortBy}&sortOrder=${sortOrder}`
      );
      const data = await response.json();
      setOrders(data.orders);
      setTotalPages(Math.ceil(data.totalOrders / 10));
    } catch (error) {
      console.error("Error fetching closed orders:", error);
    }
  };

  useEffect(() => {
    fetchClosedOrders();
    // eslint-disable-next-line
  }, [pageIndex, sortBy, sortOrder]);

  const handleUnconfirmOrder = async (orderId, type) => {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    try {
      await fetch(`${backendUrl}/api/orders/unconfirm/${orderId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ type }),
      });
      fetchClosedOrders(); // Refresh orders
      fetchTotals(); // Refetch totals
      toast.success(`${type} unconfirmed successfully.`);
    } catch (error) {
      toast.error(`Failed to unconfirm ${type} order.`);
      console.error(`Failed to unconfirm ${type} order.`);
    }
  };

  const downloadOrderPDF = (order) => {
    const doc = new jsPDF();
    let y = 10; // Initial vertical offset
    const lineHeight = 7; // Line height for each row

    const checkAndAddNewPage = () => {
      if (y > 270) {
        // Near the bottom of the page
        doc.addPage();
        y = 10; // Reset y to the top of the new page
      }
    };

    // Helper function to add text to the document
    const addText = (text, x, y, isBold = false) => {
      if (isBold) {
        doc.setFont("helvetica", "bold");
      } else {
        doc.setFont("helvetica", "normal");
      }
      doc.text(text, x, y);
      return y + lineHeight; // Increment y position for the next line
    };

    // Helper function to handle long text wrapping
    const addWrappedText = (text, x, y, maxWidth) => {
      const lines = doc.splitTextToSize(text, maxWidth); // Wrap text within the given width
      lines.forEach((line) => {
        checkAndAddNewPage();
        doc.text(line, x, y);
        y += lineHeight; // Increment y position for each line
      });
      return y; // Return the updated y position
    };

    // Title
    doc.setFontSize(16);
    y = addText("ORDER SUMMARY", 10, y, true);

    doc.setFontSize(12);
    // Order Details
    y = addText(`Order ID: ${order._id}`, 10, y);
    y = addText(`Name: ${order.name}`, 10, y);
    y = addText(`Email: ${order.email}`, 10, y);
    y = addText(`Billing Address: ${order.billingAddress}`, 10, y);
    y = addText(`Order Type: ${order.type}`, 10, y);
    y = addText(`Phone: ${order.phone}`, 10, y);
    if (order.type === "delivery") {
      y = addText(`Delivery Address: ${order.deliveryAddress}`, 10, y);
    }
    y = addText(`Event Date: ${order.eventDate.split("T")[0]}`, 10, y);
    // Check if dateOut exists before adding to PDF
    if (order.dateOut) {
      y = addText(`Date Out: ${order.dateOut.split("T")[0]}`, 10, y);
    } else {
      y = addText("Date Out: Not provided", 10, y);
    }

    // Check if dateIn exists before adding to PDF
    if (order.dateIn) {
      y = addText(`Date In: ${order.dateIn.split("T")[0]}`, 10, y);
    } else {
      y = addText("Date In: Not provided", 10, y);
    }

    // Wrap the event notes
    y = addText("Event Notes:", 10, y, true);
    if (order.notes) {
      y = addWrappedText(order.notes, 10, y, 180); // Adjust maxWidth as needed
    } else {
      y = addText("None", 10, y);
    }

    y += 5; // Additional space before items

    // Items Header
    doc.setFontSize(14);
    y = addText("ITEMS", 10, y, true);

    // Items Details
    doc.setFontSize(12);
    order.items.forEach((item) => {
      checkAndAddNewPage();
      y = addText(`Item ID: ${item.itemID}`, 10, y);
      y = addText(`Name: ${item.description}`, 10, y);
      y = addText(`Quantity: ${item.quantity}`, 10, y);
      y = addText(
        `Additional Non-Discount Qty: ${item.additionalQuantity}`,
        10,
        y
      );
      y = addText(`Price: $${item.price.toFixed(2)}`, 10, y);
      y = addText(`Discount: ${item.discount}%`, 10, y);
      y = addText(`Savings: $${item.savings.toFixed(2)}`, 10, y);
      y += 5; // Extra space after each item
    });

    checkAndAddNewPage(); // Check before adding totals
    y += 5; // Space before totals

    // Totals
    y = addText(
      `Total Rental Price: $${order.totalRentalPrice.toFixed(2)}`,
      10,
      y
    );
    y = addText(`Total Savings: $${order.totalSavings.toFixed(2)}`, 10, y);
    // Add Deposit Info
    const depositAmount = order.totalRentalPrice * 0.5;
    y = addText(`Deposit Paid: $${depositAmount.toFixed(2)}`, 10, y);
    y = addText(`Deposit Status: ${order.depositStatus}`, 10, y);
    y = addText(`Created At: ${order.createdAt}`, 10, y);
    y = addText(`Updated At: ${order.updatedAt}`, 10, y);

    doc.save(`order-details-${order._id}.pdf`);
  };

  const handleSort = (column) => {
    // Only allow sorting by specific columns
    if (
      ["eventDate", "retailPrice", "createdAt", "totalRentalPrice"].includes(
        column.id
      )
    ) {
      if (sortBy === column.id) {
        // Toggle sort order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // Set new column to sort by and default to ascending order
        setSortBy(column.id);
        setSortOrder("asc");
      }
    }
  };

  const columns = useMemo(
    () => [
      { accessorKey: "name", header: "Customer Name" },
      {
        accessorKey: "totalRentalPrice",
        header: "Total Price",
        cell: ({ getValue }) => `$${getValue().toFixed(2)}`,
        sort: true, // Allow sorting by this column
      },
      {
        accessorKey: "totalSavings",
        header: "Total Savings",
        cell: ({ getValue }) => `$${getValue().toFixed(2)}`,
      },
      {
        accessorKey: "retailPrice",
        header: "Retail Price",
        cell: ({ row }) =>
          `$${(
            row.original.totalRentalPrice + row.original.totalSavings
          ).toFixed(2)}`,
        sort: true, // Allow sorting by this column
      },
      {
        accessorKey: "eventDate",
        header: "Event Date",
        cell: ({ getValue }) =>
          new Date(getValue()).toLocaleDateString("en-US", {
            timeZone: "UTC", // Ensure the date is handled in UTC
          }),
        sort: true, // Allow sorting by this column
      },
      {
        accessorKey: "createdAt",
        header: "Date Ordered",
        cell: ({ getValue }) =>
          new Date(getValue()).toLocaleDateString("en-US", {
            timeZone: "UTC", // Ensure the date is handled in UTC
          }),
        sort: true, // Allow sorting by this column
      },
      {
        accessorKey: "operationsStatus",
        header: "Operations",
        cell: ({ row }) =>
          row.original.operationsStatus === "confirmed" ? (
            <button
              onClick={() =>
                handleUnconfirmOrder(row.original._id, "operations")
              }
            >
              Unconfirm
            </button>
          ) : (
            "Unconfirmed"
          ),
      },
      {
        accessorKey: "salesStatus",
        header: "Sales",
        cell: ({ row }) =>
          row.original.salesStatus === "confirmed" ? (
            <button
              onClick={() => handleUnconfirmOrder(row.original._id, "sales")}
            >
              Unconfirm
            </button>
          ) : (
            "Unconfirmed"
          ),
      },
      {
        accessorKey: "download",
        header: "Download PDF",
        cell: ({ row }) => (
          <button onClick={() => downloadOrderPDF(row.original)}>
            Download
          </button>
        ),
      },
    ],
    [handleUnconfirmOrder]
  );

  const table = useReactTable({
    data: orders,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="admin-table-container">
      <table className="admin-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={
                    header.column.columnDef.sort ? "sortable-header" : ""
                  }
                  onClick={() => handleSort(header)}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {sortBy === header.id && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {orders.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="no-quotes">
                No Quotes
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="admin-pagination">
        <button
          onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}
          disabled={pageIndex === 0}
        >
          Previous
        </button>
        <button
          onClick={() =>
            setPageIndex((prev) => Math.min(prev + 1, totalPages - 1))
          }
          disabled={pageIndex >= totalPages - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ClosedTable;
