// OrderSummaryPage.js
import React from "react";
import { useLocation } from "react-router-dom";
import OrderDetails from "../components/orderSummary/orderDetails";
import OrderItemsList from "../components/orderSummary/orderItemsList";
import { useNavigate } from "react-router-dom";
import { IconCircleCheck } from "@tabler/icons-react";
const OrderSummaryPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const orderDetails = state ? state.orderDetails : null; // Check if state exists

  if (!orderDetails) {
    return <div>Order details not found.</div>; // Or navigate back, or any other handling
  }

  return (
    <div>
      <IconCircleCheck size={75} color="green"></IconCircleCheck>
      <h3>
        Your quote request was successfully submitted to our sales team for
        review. You will receive a confirmation and payment instructions
        shortly.
      </h3>
      <OrderDetails orderInfo={orderDetails} />
      <OrderItemsList items={orderDetails.items} />
      <div style={{ marginTop: "20px" }}>
        <button onClick={() => navigate("/")}>CHOOSE NEW EVENT DATE</button>
      </div>
    </div>
  );
};

export default OrderSummaryPage;
