// src/components/UserDashboard.js
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import UserOrders from "../../components/userDashboard/userOrders";
import Settings from "../../components/userDashboard/userSettings";
import UserFavorites from "../../components/userDashboard/userFavorites";
import {
  IconShoppingBag,
  IconUserCircle,
  IconHeart,
  IconLogout,
} from "@tabler/icons-react";
import { IconSettings } from "@tabler/icons-react";
import "./userDashboardPage.css";

function UserDashboard() {
  const [activeTab, setActiveTab] = useState("orders");
  const { currentUser, logout } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Set active tab based on query parameter
  useEffect(() => {
    const tab = searchParams.get("activeTab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  const handleLogout = () => {
    logout();
    navigate("/"); // Redirect to home or login page after logout
  };

  return (
    <div className="user-dashboard">
      <div className="sidebar">
        <h2>DASHBOARD</h2>
        <ul>
          <li
            className={activeTab === "orders" ? "active" : ""}
            onClick={() => setActiveTab("orders")}
          >
            <IconShoppingBag /> Your Orders
          </li>
          <li
            className={activeTab === "settings" ? "active" : ""}
            onClick={() => setActiveTab("settings")}
          >
            <IconSettings /> Account Details
          </li>
          <li
            className={activeTab === "favorites" ? "active" : ""}
            onClick={() => setActiveTab("favorites")}
          >
            <IconHeart /> Your Favorites
          </li>
          <li onClick={handleLogout}>
            {" "}
            <IconLogout /> Log Out
          </li>
        </ul>
      </div>
      <div className="profile">
        <div className="greeting">Hello {currentUser?.displayName}!</div>
        <IconUserCircle size={48} color="black" />
      </div>
      <div className="user-main-content">
        {activeTab === "orders" && <UserOrders />}
        {activeTab === "settings" && <Settings />}
        {activeTab === "favorites" && <UserFavorites />}
      </div>
    </div>
  );
}

export default UserDashboard;
