import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import toast from "react-hot-toast";
import * as Dialog from "@radix-ui/react-dialog";
import "./authStyles.css";
import { Link } from "react-router-dom";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Login successful!");
      navigate("/");
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        toast.error("You have entered an invalid username or password");
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      toast.error("Please enter your email.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      toast.success("Password reset email sent!");
      setIsDialogOpen(false);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast.error("No account exists with that email.");
      } else {
        console.error("Reset password error:", error);
        toast.error("Failed to send reset password email.");
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase()); // Convert email to lowercase
  };

  return (
    <div className="auth-container">
      <h2 className="auth-title">Welcome Back</h2>
      <p>Log-in to your account</p>
      <form onSubmit={handleLogin} className="auth-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange} // Use the handleEmailChange function
          required
          className="auth-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="auth-input"
        />
        <span
          className="forgot-password-link"
          onClick={() => setIsDialogOpen(true)}
        >
          Forgot Password?
        </span>
        <button type="submit" className="auth-button">
          LOGIN
        </button>
      </form>
      <p>
        Don't have an account?{" "}
        <Link
          to="/user/signup"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          Sign Up
        </Link>
      </p>
      {/* Radix Dialog for Password Reset */}
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="dialog-overlay" />
          <Dialog.Content className="dialog-content">
            <Dialog.Title className="dialog-title">Reset Password</Dialog.Title>
            <Dialog.Description className="dialog-description">
              Enter your email to receive a password reset link.
            </Dialog.Description>
            <input
              type="email"
              placeholder="Enter your email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              className="dialog-input"
              required
            />
            <div className="dialog-buttons">
              <button
                onClick={handleResetPassword}
                className="dialog-button confirm"
              >
                Send Reset Email
              </button>
              <Dialog.Close asChild>
                <button className="dialog-button cancel">Cancel</button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default UserLogin;
