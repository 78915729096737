import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/authContext";
import "./userOrders.css";

function UserOrders() {
  const { currentUser, userDetails } = useAuth();
  const [orders, setOrders] = useState([]);
  const [totalSavings, setTotalSavings] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState("confirmed");
  const [showFullOrder, setShowFullOrder] = useState({}); // State to manage which orders have full details visible

  useEffect(() => {
    const fetchOrders = async () => {
      if (userDetails._id && currentUser) {
        setIsLoading(true);
        try {
          const token = await currentUser.getIdToken();
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/orders/user-orders/${userDetails._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setOrders(response.data.orders);
          setTotalSavings(response.data.totalSavings);
          setIsLoading(false);
        } catch (error) {
          console.error("Failed to fetch orders:", error);
          setError("Failed to fetch orders");
          setIsLoading(false);
        }
      }
    };

    fetchOrders();
  }, [currentUser, userDetails._id]);

  const handleShowFullOrder = (orderId) => {
    setShowFullOrder((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const renderOrderDetails = (order, index, isConfirmed) => (
    <div key={order._id} className="user-order-box">
      <div className="user-order-header">
        <p className="user-event-date">
          Event Date: {order.eventDate.split("T")[0]}
        </p>
        <p className="user-order-type">
          Order Type: {order.type === "willCall" ? "Will Call" : "Delivery"}
        </p>
        <p className="user-total-saving">
          Total Savings: ${order.totalSavings.toFixed(2)}
        </p>
        <p className="user-total-rental-price">
          Total Price: ${order.totalRentalPrice.toFixed(2)}
        </p>
      </div>

      <div>
        <div className="user-order-items">
          {/* Only show the first item */}
          {order.items.slice(0, 1).map((item, itemIndex) => (
            <div key={itemIndex} className="user-item-details">
              <img
                src={`${process.env.REACT_APP_BLOB_IMAGE_URL}/${item.itemID}.jpg`}
                alt={item.description}
                className="user-item-image"
              />
              <div className="user-item-info">
                <p style={{ fontWeight: "bold" }}>{item.description}</p>
                <p>Discounted Quantity: {item.quantity}</p>
                <p>Non-Discounted Quantity: {item.additionalQuantity}</p>
                <p>Discount: {item.discount}%</p>
              </div>
            </div>
          ))}
          {/* Button to show the rest of the items */}
          {order.items.length > 1 && !showFullOrder[order._id] && (
            <div className="user-show-more-button-container">
              <button
                onClick={() => handleShowFullOrder(order._id)}
                className="user-show-more-button"
              >
                Show More +
              </button>
            </div>
          )}
          {showFullOrder[order._id] &&
            order.items.slice(1).map((item, itemIndex) => (
              <div key={itemIndex} className="user-item-details">
                <img
                  src={`${process.env.REACT_APP_BLOB_IMAGE_URL}/${item.itemID}.jpg`}
                  alt={item.description}
                  className="user-item-image"
                />
                <div className="user-item-info">
                  <p style={{ fontWeight: "bold" }}>{item.description}</p>
                  <p>Discounted Quantity: {item.quantity}</p>
                  <p>Non-Discounted Quantity: {item.additionalQuantity}</p>
                  <p>Discount: {item.discount}%</p>
                </div>
              </div>
            ))}
          {/* Button to show less */}
          {showFullOrder[order._id] && (
            <div className="user-show-more-button-container">
              <button
                onClick={() => handleShowFullOrder(order._id)}
                className="user-show-more-button"
              >
                Show Less -
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="user-orders-layout">
      <div className="user-tabs">
        <div
          className={`user-tab ${selectedTab === "confirmed" ? "active" : ""}`}
          onClick={() => setSelectedTab("confirmed")}
        >
          Confirmed Orders
        </div>
        <div
          className={`user-tab ${selectedTab === "pending" ? "active" : ""}`}
          onClick={() => setSelectedTab("pending")}
        >
          Pending Orders
        </div>
      </div>

      {selectedTab === "confirmed" && (
        <h3 className="user-total-savings">
          LIFETIME PRIORUM SAVINGS: ${totalSavings.toFixed(2)}
        </h3>
      )}

      <div className="user-orders-section">
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : selectedTab === "confirmed" ? (
          orders.filter(
            (order) =>
              order.operationsStatus === "confirmed" &&
              order.salesStatus === "confirmed"
          ).length > 0 ? (
            orders
              .filter(
                (order) =>
                  order.operationsStatus === "confirmed" &&
                  order.salesStatus === "confirmed"
              )
              .map((order, index) => renderOrderDetails(order, index, true))
          ) : (
            <p>No confirmed orders.</p>
          )
        ) : selectedTab === "pending" ? (
          orders.filter(
            (order) =>
              order.operationsStatus === "pending" ||
              order.salesStatus === "pending"
          ).length > 0 ? (
            orders
              .filter(
                (order) =>
                  order.operationsStatus === "pending" ||
                  order.salesStatus === "pending"
              )
              .map((order, index) => renderOrderDetails(order, index, false))
          ) : (
            <p>No pending orders.</p>
          )
        ) : null}
      </div>
    </div>
  );
}

export default UserOrders;
