import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./checkListPage.css"; // Add CSS for styling

const CheckListPage = () => {
  const navigate = useNavigate();
  const checklistItems = [
    "Priorum is a new product that is still in development, and your feedback on it is welcome.",
    "Your event is 30-days or more from today’s date.",
    "Non-refundable 50% deposit required, if your event gets canceled, we'll apply the deposit to a future order.",
    "Delivery fees and production services are not included.",
    "Once you place your quote request on the Priorum website, you will be contacted by a member of the Abbey Party Rents team to finalize details before you receive a contract for your order.",
    "Your order must be confirmed within 24-hours of receiving the final quote from your salesperson.",
  ];

  const [checkedItems, setCheckedItems] = useState(
    new Array(checklistItems.length).fill(false)
  );

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
  };

  const allChecked = checkedItems.every((item) => item);

  return (
    <div className="checklist-container">
      <h1>New User Access Acknowledgement Checklist</h1>
      <h4>Check the boxes to proceed!</h4>
      <ul>
        {checklistItems.map((item, index) => (
          <li key={index}>
            <label>
              <input
                type="checkbox"
                checked={checkedItems[index]}
                onChange={() => handleCheckboxChange(index)}
              />
              {item}
            </label>
          </li>
        ))}
      </ul>
      {allChecked && (
        <button className="go-to-home-btn" onClick={() => navigate("/")}>
          Proceed to Priorum
        </button>
      )}
    </div>
  );
};

export default CheckListPage;
