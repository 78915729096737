import React from "react";
import AdminRequest from "../../components/requestAccess/adminRequest";
import CheckStatus from "../../components/requestAccess/checkStatus";
import RequestInstructions from "../../components/requestAccess/requestInstructions";
import { NavLink } from "react-router-dom";

function RequestAccess() {
  return (
    <div>
      <h2 style={{ textAlign: "center", color: "#0056b3" }}>
        PRIORUM STAFF REQUEST
      </h2>
      <AdminRequest />
      <h2 style={{ textAlign: "center", color: "#0056b3" }}>CHECK STATUS</h2>
      <CheckStatus type="admin" />
      <p>
        * If status of account is active a link will show up to create an
        account!
      </p>
      <p>
        Already Created Account? <NavLink to="/admin/login">Login</NavLink>
      </p>
      <RequestInstructions />
    </div>
  );
}

export default RequestAccess;
