// OrderList.jsx
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../../store/cartSlice";
import { useNavigate } from "react-router-dom";
import { OrderForm } from "../orderForm/orderForm";
import { useAuth } from "../../context/authContext";
import "./orderList.css";
import { IconTrash } from "@tabler/icons-react";
import QuantityExceededModal from "../quantityExceededModal/quantityExceededModal";
import ConfirmOrderModal from "../confirmOrderModal/confirmOrderModal";
import { Link } from "react-router-dom";

export default function OrderList() {
  const cart = useSelector((state) => state.cart.items);
  const eventDate = useSelector((state) => state.eventDate); // Access the date from Redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, userDetails } = useAuth();

  // State to manage modal visibility
  const [modalData, setModalData] = useState({ isOpen: false, catLink: "" });

  const [isModalOpen, setIsModalOpen] = useState(false); // Confirm Order Modal State

  const [formData, setFormData] = useState(null);

  const handleRemove = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this item?"
    );
    if (isConfirmed) {
      dispatch(removeFromCart(id));
    }
  };

  const calculateItemFinals = (item) => {
    const pricePerItem = item.itemDetails.RentalPrice;
    const discountPercentage =
      item.itemDetails.DiscountsApplied?.[0]?.discountPercentage || 0;
    const discountedPrice =
      item.quantity * pricePerItem * (1 - discountPercentage / 100);
    const regularPrice = (item.regularQuantity || 0) * pricePerItem;
    const finalPrice = discountedPrice + regularPrice;
    const totalSavings =
      item.quantity * pricePerItem * (discountPercentage / 100);

    return {
      orgPrice: pricePerItem * (item.quantity + (item.regularQuantity || 0)),
      finalPrice,
      savings: totalSavings,
    };
  };

  // Calculate totals for all items in the cart
  const totals = cart.reduce(
    (acc, item) => {
      const { finalPrice, savings } = calculateItemFinals(item);
      acc.totalRentalPrice += finalPrice;
      acc.totalSavings += savings;
      return acc;
    },
    { totalRentalPrice: 0, totalSavings: 0 }
  );

  if (cart.length === 0) {
    return (
      <div>
        <p>Your cart is empty.</p>
        <button onClick={() => navigate("/")}>BACK TO HOME</button>
      </div>
    );
  }

  const handleFormSubmit = async (submittedFormData) => {
    setFormData(submittedFormData); // Save form data for later use in confirmation
    setIsModalOpen(true); // Open the confirmation modal
  };

  const handleConfirmOrder = async () => {
    setIsModalOpen(false); // Close modal after confirmation

    if (!formData) {
      console.error("Form data is missing");
      return;
    }

    // Prepare the order details
    const orderDetails = {
      ...formData,
      userId: userDetails._id,
      items: cart.map((item) => ({
        itemID: item.id,
        description: item.itemDetails.ItemDescription,
        quantity: item.quantity,
        additionalQuantity: item.regularQuantity || 0,
        price: calculateItemFinals(item).finalPrice,
        discount:
          item.itemDetails.DiscountsApplied?.[0]?.discountPercentage || 0,
        savings: calculateItemFinals(item).savings || 0,
      })),
      totalRentalPrice: totals.totalRentalPrice,
      totalSavings: totals.totalSavings,
      status: "pending",
    };

    // Navigate to /payment with the orderDetails passed in the state
    navigate("/payment", { state: { orderDetails } });
  };

  const goToFavorites = () => {
    navigate("/user-dashboard?activeTab=favorites");
  };

  const handleCancelOrder = () => {
    setIsModalOpen(false); // Close the modal
    navigate("/"); // Navigate to the DateSelection route
  };

  return (
    <div className="order-list-container">
      <QuantityExceededModal
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ ...modalData, isOpen: false })}
        catLink={modalData.catLink}
      />
      <div className="cart-items-container">
        <h2 className="cart-header">YOUR PRIORUM CART</h2>
        {cart.map((item) => (
          <div key={item.id} className="cart-item">
            <img
              src={`${process.env.REACT_APP_BLOB_IMAGE_URL}/${item.id}.jpg`}
              alt={item.itemDetails.ItemDescription}
              className="cart-item-image"
            />
            <div className="cart-item-details">
              <div className="item-description">
                <h3>{item.itemDetails.ItemDescription}</h3>
                <div className="price-quantity">
                  {calculateItemFinals(item).orgPrice.toFixed(2) !==
                  calculateItemFinals(item).finalPrice.toFixed(2) ? (
                    <>
                      <span className="strikethrough">
                        ${calculateItemFinals(item).orgPrice.toFixed(2)}
                      </span>
                      <span className="final-price">
                        ${calculateItemFinals(item).finalPrice.toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <span className="final-price">
                      ${calculateItemFinals(item).finalPrice.toFixed(2)}
                    </span>
                  )}
                </div>
                <div className="quantity-remove">
                  <p>
                    Total Quantity:{" "}
                    {item.quantity + (item.regularQuantity || 0)}
                  </p>
                  <p>(Discounted Quantity: {item.quantity})</p>
                  <button
                    onClick={() => handleRemove(item.id)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <IconTrash />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="checkout-container">
        {currentUser && (
          <button onClick={goToFavorites}>GO TO FAVORITES</button>
        )}
        <div className="total-box">
          <h3>Total</h3>
          <p>Total Rental Price: ${totals.totalRentalPrice.toFixed(2)}</p>
          <p>Total Savings: ${totals.totalSavings.toFixed(2)}</p>
        </div>

        <p>* Price does not include delivery and labor</p>
        <p>* ORDER IS NOT FINAL UNTIL PAYMENT IS MADE</p>

        {currentUser ? (
          <OrderForm
            onSubmit={(formData) => handleFormSubmit(formData)}
            initialValues={{
              name: currentUser?.displayName || "", // Fallback to an empty string if null
              email: currentUser?.email || "",
              phone: userDetails?.phone || "",
              address: userDetails?.address || "",
              zip: userDetails?.zip || "",
              city: userDetails?.city || "",
              state: userDetails?.state || "",
            }}
          />
        ) : (
          <div className="auth-prompt">
            <p>
              To complete your order, please{" "}
              <Link
                to="/user/signup"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                sign up
              </Link>
              . If you already have an account, please{" "}
              <Link
                to="/user/login"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                log in
              </Link>
              !
            </p>
          </div>
        )}
      </div>
      <ConfirmOrderModal
        isOpen={isModalOpen}
        message={
          <span>
            Are you sure you want to continue to payment? Please confirm your
            event date of
            <strong>
              {" "}
              {new Date(eventDate.date).toLocaleDateString()}{" "}
            </strong>{" "}
            is correct.
          </span>
        }
        onConfirm={handleConfirmOrder} // Call the actual form submit after confirmation
        // onCancel={() => setIsModalOpen(false)} // Close the modal if they cancel
        onCancel={handleCancelOrder}
      />
    </div>
  );
}
