import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { clearDate } from "../../store/dateSlice";
import { clearCart } from "../../store/cartSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function CheckoutForm({
  paymentIntentClientSecret,
  orderDetails,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Handle submitting the payment
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com",
      },
      redirect: "if_required",
    });

    if (error) {
      console.error("Payment Error:", error.message); // Log the error
      setMessage(error.message);
    } else if (paymentIntent.status === "succeeded") {
      handleSuccess(paymentIntent); // Handle success after payment is confirmed
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsProcessing(false);
  };

  // Handle success logic and save the order after payment
  const handleSuccess = async (paymentIntent) => {
    try {
      // Proceed with saving the order after payment confirmation
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/orders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...orderDetails,
            depositStatus: "paid",
            paymentIntentId: paymentIntent.id,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        toast.success("Order placed successfully!");
        dispatch(clearCart());
        dispatch(clearDate());
        navigate("/order-summary", { state: { orderDetails: data.order } });
      } else {
        toast.error("Error saving the order.");
      }
    } catch (error) {
      console.error("Error saving the order:", error);
      toast.error("Error saving the order.");
    }
  };

  return (
    <div>
      <form id="payment-form" onSubmit={onSubmit}>
        <PaymentElement id="payment-element" />
        <button type="submit" disabled={isProcessing || !stripe || !elements}>
          <span id="button-text">
            {isProcessing ? "Processing ..." : "PAY DEPOSIT NOW"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
