import React from "react";
import "./termsConditions.css";

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <h1>Priorum and Abbey Party Rents Terms & Conditions</h1>

      <p>
        <strong>
          PRIORUM ORDERS MUST BE CONFIRMED WITHIN 24-HOURS OF QUOTE RECEIPT.
        </strong>
      </p>
      <p>
        <strong>50% NON-REFUNDABLE DEPOSIT ON ALL PRIORUM ORDERS.</strong>
      </p>
      <p>
        <strong>
          ORDERS CANCELED WITHIN 72-HOURS OF DELIVERY DATE WILL INCUR A 25%
          CANCELATION FEE.
        </strong>
      </p>

      <p>
        Discounted rates cannot be applied for any date changes. Changes made to
        the delivery date or pick-up date, or items returned late, will incur
        extended rental fees at the full listed rate on the Abbey Party Rents
        website, with no Priorum discounting.
      </p>

      <p>
        For the purpose of this Rental Agreement, "Rental Center'' shall mean
        Serazuki Enterprises DBA Priorum and Abbey Party Rents, its
        owners/officers, directors, shareholders, and employees, and "Customer"
        shall mean Customer, its agents and/or employees. In consideration of
        hiring of the items (herein "the rental items or items") described on
        the front of this Rental Agreement it is agreed as follows:
      </p>

      <h3>INDEMNITY/HOLD HARMLESS</h3>
      <p>
        CUSTOMER WILL TAKE ALL NECESSARY PRECAUTIONS REGARDING THE ITEMS RENTED,
        AND PROTECT ALL PERSONS AND PROPERTY FROM INJURY OR DAMAGE. CUSTOMER
        AGREES TO HOLD HARMLESS RENTAL CENTER FROM AND AGAINST ANY AND ALL
        LIABILITY, CLAIMS, JUDGMENTS, ATTORNEYS' FEES AND COSTS, OF EVERY KIND
        AND NATURE, INCLUDING, BUT NOT LIMITED, TO INJURIES OR DEATH TO PERSONS
        AND DAMAGE TO PROPERTY, ARISING OUT OF THE USE, MAINTENANCE,
        INSTRUCTION, OPERATION, POSSESSION, OWNERSHIP OR RENTAL OF THE ITEMS
        RENTED, HOWEVER CAUSED, EXCEPT CLAIMS OR LITIGATION ARISING THROUGH THE
        SOLE NEGLIGENCE OR WILLFUL MISCONDUCT OF RENTAL CENTER.
      </p>

      <h3>ASSUMPTION OF RISK/RELEASE-DISCHARGE OF LIABILITY</h3>
      <p>
        CUSTOMER IS FULLY AWARE AND ACKNOWLEDGES THERE IS A RISK OF INJURY OR
        DAMAGE ARISING OUT OF THE USE OR OPERATION OF THE ITEMS RENTED HERE
        UNDER AND HEREBY ELECTS TO VOLUNTARILY ENTER INTO THIS RENTAL AGREEMENT
        AND ASSUME ALL OF THE ABOVE RISKS OF INJURY OR DAMAGE. CUSTOMER AGREES
        TO RELEASE AND DISCHARGE RENTAL CENTER FROM ANY AND ALL RESPONSIBILITY
        OR LIABILITY FROM SUCH INJURY OR DAMAGE ARISING OUT OF THE USE OR
        OPERATION OF THE RENTAL ITEMS; AND CUSTOMER FURTHER AGREES TO WAIVE,
        RELEASE AND DISCHARGE ANY AND ALL CLAIMS FOR INJURY OR DAMAGE AGAINST
        RENTAL CENTER WHICH CUSTOMER OTHERWISE MAY BE ENTITLED TO ASSERT.
      </p>

      <h3>TITLE AND OWNERSHIP</h3>
      <p>
        The items rented shall at all times be and remain the sole and exclusive
        property of Rental Center. Customer shall have only the rights to use
        the rental items in accordance with the terms of this agreement. Rental
        Center shall have the right to display notice of its ownership of the
        rental items by display of an identifying stencil, plate or other
        marking, and Customer agrees that it will not remove or cover such
        markings without the written permission of Rental Center. It is
        expressly intended and agreed that the rental items shall be personal
        property even though it may be affixed or attached to real estate. The
        rental items shall not be removed from the place of delivery or
        installation without the expressed written permission of Rental Center.
      </p>

      <h3>INSPECTION</h3>
      <p>
        Customer acknowledges that he has had an opportunity to personally
        inspect the rental items and finds it suitable for his needs and in good
        condition. Customer understands its proper use. Customer further
        acknowledges Customer responsibility to inspect the rental items prior
        to its use and to notify Rental Center of any defects.
      </p>

      <h3>REPLACEMENT OF MALFUNCTIONING ITEMS</h3>
      <p>
        If the rental items become unsafe or in disrepair for any reason,
        Customer agrees to discontinue its use and to notify Rental Center.
        Rental Center will repair or replace the items with similar items in
        good working order if available, and if the defect is the result of
        normal use. Rental Center is not responsible for any incidental or
        consequential damages caused by delays or otherwise, and Customer hereby
        waives any right or entitlement thereto.
      </p>

      <h3>WARRANTIES</h3>
      <p>
        Rental Center is not the manufacturer of the rented property nor the
        agent of the manufacturer, and no warranty against patent or latent
        defects in material workmanship or capacity is given, and Customer
        expressly waives all such warranties of fitness which may be accorded by
        law or otherwise. There are no warranties of merchantability or fitness,
        either express or implied. There is no warranty that the rental items
        are suited for Customer's intended use, or that it is free from defects,
        and any and all such warranties of fitness, or otherwise, are expressly
        and specifically waived by customer.
      </p>

      <h3>TIME OF RETURN</h3>
      <p>
        Customer right of possession terminates on the expiration of the rental
        period and retention of possession after this time constitutes a
        material breach of Customer's obligations under this contract. Time is
        of the essence in this agreement. Any extension must, at Rental Center's
        election be mutually agreed upon in writing. WE CHARGE FOR ALL TIME OUT
        - NOT TIME USED.
      </p>

      <h3>ASSIGNMENTS, SUBLEASES AND LOANS OF RENTAL ITEMS</h3>
      <p>
        The Rental Center may assign its right under this contract without the
        Customer's consent, but will remain bound by all obligations herein. The
        Customer may not sublease or loan the rental items without the Rental
        Center's written permission. Any purported assignment by the Customer is
        void.
      </p>

      <h3>RETURN OF RENTAL ITEMS</h3>
      <p>
        At the termination of this agreement, Customer shall return all the
        rental items to Rental Center's premises during Rental Center's regular
        business hours, in the condition and repair as when delivered to
        Customer, subject only to reasonable wear and tear. Customer shall be
        liable for all damages to or loss of the Equipment occurring because it
        was not returned within Rental Center's regular business hours. If
        Rental Center has agreed to deliver the Equipment to Customer or to pick
        up the Equipment from Customer. Customer shall be responsible for all
        losses or damage to the Equipment from time of delivery to Customer and
        until picked up by Rental Center.
      </p>

      <h3>INSPECTION BY RENTAL CENTER</h3>
      <p>
        Rental Center shall at all times have the right to enter any premises
        where the Equipment may be located for purposes of inspecting it,
        observing its use, or removing it from Customer's premise.
      </p>

      <h3>COMPLIANCE WITH LAWS/USE OF RENTAL ITEMS</h3>
      <p>
        Customer agrees not to use or allow anyone to use the rental items for
        any illegal purpose or in any illegal manner or in an unsafe manner.
        Customer agrees at his sole cost and expense to comply with all
        municipal, county, state and federal laws, ordinances and regulations
        which may apply to the use of the rental items during the rental period.
        Customer further agrees to pay all licenses, fines, fees, permits, or
        taxes arising from his use of the rental items, including any
        subsequently determined to be due. Customer is responsible for obtaining
        all permits and/or licenses from the appropriate governmental agencies.
        Rental Center may at the Customer's request act as the agent to obtain
        required permits and/or licenses. If these agencies should require
        additional rental items, the expense of these rental items will be the
        sole responsibility of the Customer. If the permits or licenses are
        denied for any reason, Customer is still responsible for all financial
        and other obligations pursuant to this Agreement to Rental Center or its
        subcontractors..Customer shall not allow any person who is not qualified
        and who has not received and understands safety and operating
        instructions and who does not utilize all safety equipment required, to
        operate the rental items or use the rental items. Customer shall not
        allow any person to use or operate the rental items when it is in need
        of repair or when it is in an unsafe condition or situation; modify,
        misuse, harm or abuse the rental items; permit any repairs to the rental
        items without Rental Center's prior written permission; or, allow a lien
        to be placed upon the rental items. Customer agrees to check filters,
        oil, fluid levels, air pressure, clean and visually inspect the rental
        items at least daily and to immediately discontinue use and notify
        Rental Center when rental items are found to need repair or maintenance
        or is not properly functioning. Customer acknowledges that Rental Center
        has no responsibility to inspect the rental items while they are in
        Customer's possession
      </p>

      <h3>DELIVERY/PICK UP</h3>
      <p>
        Delivery is made to closest point truck can park. Extra charges will
        result in deliveries to upstairs, elevator use or any point where extra
        time is involved. Our service does not include set up and knock down of
        tables and chairs. If this service is required, arrangements should be
        made several days prior to delivery with a special charge quoted. If no
        arrangements are made and this service is desired on delivery, our
        driver must call for authorization. If time permits, we will try to
        accommodate you after quoting the price. On pick up where no prior
        arrangements have been made and rental items are not knocked down and
        assembled in one sheltered area, tables and chairs will be left until
        the next day when a special crew can be scheduled. There will be an
        additional one day rental. A knock down fee will result if rental items
        are still up.
      </p>

      <h3>CLEANING</h3>
      <p>
        China, Glassware, and Flatware must be returned rinsed and repacked
        properly in boxes provided or additional charges will be assessed.
        Special cleaning deposits will be charged on BBQ Grills and Cooking
        Equipment.
      </p>

      <h3>LINENS</h3>
      <p>
        Table linens are inspected prior to pick up and upon return. DO NOT ROLL
        UP OR PLACE WET LINENS IN ANY BAG- mildew will result. If there is
        obvious damage such as mildew, excessive stains, burns or tears, you
        will be charged the cost of the linen and keep the same as though it
        were a sale. Return all linens dry and free of waste.
      </p>

      <h3>DEPOSITS</h3>
      <p>
        50% NON-REFUNDABLE DEPOSIT ON ALL PRIORUM ORDERS. ORDERS CANCELED WITHIN
        72-HOURS OF DELIVERY DATE WILL INCUR A 25% CANCELATION FEE. Discounted
        rates cannot be applied for any date changes. Changes made to the
        delivery date or pick-up date, or items returned late, will incur
        extended rental fees at the full listed rate on the Abbey Party Rents
        website, with no Priorum discounting.
      </p>

      <h3>TIME OF PAYMENT</h3>
      <p>
        PRIORUM ORDERS MUST BE CONFIRMED WITHIN 24-HOURS OF QUOTE RECEIPT ALONG
        WITH 50% NON-REFUNDABLE DEPOSIT. A carrying charge of 1.5% per month
        (ANNUAL RATE OF 18%) will be charged on all overdue accounts.
      </p>

      <h3>CREDIT CARD PROCESSING FEE</h3>
      <p>
        By using a credit card to make a payment, Customer agrees to pay a
        processing fee of 3% of the total transaction amount. This processing
        fee covers the costs associated with processing credit card
        transactions, including but not limited to interchange fees,
        assessments, and processing costs incurred by Rental Center. By
        providing Your credit card information for payment, You authorize Rental
        Center to charge your credit card for the total transaction amount plus
        the applicable processing fee.
      </p>

      <h3>CANCELLATIONS</h3>
      <p>
        50% NON-REFUNDABLE DEPOSIT ON ALL PRIORUM ORDERS. ORDERS CANCELED WITHIN
        72-HOURS OF DELIVERY DATE WILL INCUR A 25% CANCELATION FEE. A 100%
        restocking fee will apply for all items cancelled within 3 calendar days
        of delivery or will call.
      </p>

      <h3>DIRTY, OR DAMAGED ITEMS</h3>
      <p>
        Customer agrees to pay for any damage to rental items regardless of
        cause, except reasonable wear and tear, while rental items are out of
        possession of Rental Center. Customer also agrees to pay a reasonable
        cleaning charge for all rental items returned dirty. Accrued rental
        charges cannot be applied against the purchase or cost of repair or
        damaged goods. Rental Equipment damaged beyond repair will be paid for
        by Customer at its Replacement Cost when rented. The cost of repairs
        will be borne by the Customer, whether performed by Rental Center, or at
        the Rental Center's option by others.
      </p>

      <h3>DAMAGE WAIVER</h3>
      <p>
        If accepted by Customer, Rental Center agrees, in consideration of an
        additional charge of 12.5% of the gross rental charges, to modify the
        responsibilities of Customer created in paragraph 16 [Dirty or Damaged
        Equipment]. For Damage Waiver Charge provided the Customer takes
        reasonable precautions to protect rental items. The Rental Center
        assumes risk of damage to rental items, except the following risks
        assumed by the Customer: (a) Loss, damage, vandalism, malicious
        mischief, and theft [b) Loss, damage or theft of accessory items such as
        extension cords, etc. (c) Loss due to mysterious disappearance or
        wrongful conversion by a person entrusted with rental items. [d) Damage
        waiver is null and void if damage is caused by a third party not
        associated or related to Customer. In this instance the Rental Center
        reserves the right to collect from person or company causing damage. THE
        LESSEE UNDERSTANDS THAT THE DAMAGE WAIVER IS NOT INSURANCE. THE LESSEE
        IS OBLIGATED TO SUBMIT TO THE LESSOR A POLICE REPORT ON ALL LOSSES
        COVERED UNDER DAMAGE WAIVER PLAN.
      </p>

      <h3>THEFT OF RENTAL ITEMS</h3>
      <p>
        The Customer agrees to pay for rental items (at its replacement cost
        when rented) for all types of theft or mysterious disappearance. Damage
        Waiver does not cover theft.
      </p>

      <h3>RETAKING OF RENTAL ITEMS</h3>
      <p>
        {" "}
        If for any reason it becomes necessary for Rental Center to retake the
        rental items, Customer authorizes rental center to retake the rental
        items without further notice or further legal process and agrees that
        Rental Center shall not be liable for any claims for damage or trespass
        arising out of the removal of the rental items.
      </p>

      <h3>LEGAL FEES</h3>
      <p>
        In the event an attorney is retained to enforce any provision of this
        Rental Agreement, the prevailing party in the dispute shall be entitled
        to seek reasonable attorney's fees and court costs in such action, or
        proceeding, in an amount to be determined by the court.
      </p>

      <h3>COVID-19</h3>
      <p>
        The customer acknowledges and agrees that there is an inherent risk of
        exposure to COVID-19 in any public place where people are present and
        that the customer voluntarily assumes all risks related to exposure to
        COVID-19.The customer represents and warrants that they will comply with
        all governmental laws, regulations, orders, and guidelines applicable to
        COVID-19 when using the Rental Center services and equipment. To the
        fullest extent permitted by applicable law, the customer, on behalf of
        themselves and their heirs, executors, administrators, and assigns,
        hereby releases, waives, discharges, and covenants not to sue the
        Company, its officers, directors, employees, agents, and
        representatives, from any and all liabilities, claims, actions, damages,
        costs, or expenses of any kind arising out of or relating to any
        exposure to COVID-19 related to the use of the Company's services and
        equipment.
      </p>

      <h3>FORCE MAJEURE</h3>
      <p>
        In the event that performance of this Agreement, or any obligation
        hereunder, is either directly or indirectly prevented, restricted, or
        interfered with by reason of fire, flood, earthquake or like acts of
        God, wars, revolution, civil commotion, explosion, acts of public enemy,
        embargo, acts of the government in its sovereign capacity. labor
        difficulties. including without limitation. strikes. slowdowns,
        picketing. or boycotts. Unavailability of equipment from vendor, changes
        requested by Customer, or any other circumstances beyond the reasonable
        control and without the fault or negligence of the Rental Center, the
        Rental Center, upon giving prompt notice to the Customer, shall be
        excused from such performance on a day-to-day basis to the extent of
        such prevention, restriction, or interference (and the Customer shall
        likewise be excused from performance of its obligations on a day-to-day
        basis until the delay, restriction or interference has ceased); provided
        however, that the Rental Center shall use diligent efforts to avoid or
        remove such causes of non-performance and both Parties shall proceed
        whenever such causes are removed or cease.
      </p>

      <h3>WEATHER RELATED RISKS</h3>
      <p>
        Customer assumes all weather related risks involved in holding an
        outdoor tented event. Rental Center will endeavor to minimize said risk,
        however, should the tenting become unusable due to high wind, snow,
        rain, flooding, extreme cold or heat, or any other factor beyond Rental
        Center's control, Customer shall still be liable for payment in full of
        all charges.
      </p>

      <h3>PREPARATION OF SITE</h3>
      <p>
        Customer agrees to have the site upon which the rental items are to be
        erected, free and clear of all obstacles, natural and man made, prior to
        the arrival of the Rental Center's work crew. Customer further agrees to
        have all tents cleared for removal prior to our arrival. All non-rented
        items and decorations shall be cleared and taken from site. If Customer
        fails to do so, then Customer shall pay all costs involved for any
        delay, additio
      </p>

      <h3>MATERIAL</h3>
      <p>
        All tents are subject to stretching and retracting of up to 5% of listed
        sizes and although all tents have been impregnated with waterproofing
        compound, no tents are guaranteed to be absolutely waterproof, and are
        to be considered temporary shade structures.
      </p>

      <h3>COOKING UNDER TENTS</h3>
      <p>
        Customer agrees not to do any type of cooking under or within a
        reasonable distance of the tent. Customer assumes full responsibility
        and costs incurred for damage and or cleaning expense to tent tops due
        to cooking processes under or near tents.
      </p>

      <h3>ELECTRIC POWER AND LIGHTING</h3>
      <p>
        Customer agrees to furnish Rental Center access to, and the right to use
        Customer's electrical and power lines for the installation and operation
        of the rental items.
      </p>

      <h3>UNDERGROUND FACILITIES</h3>
      <p>
        Customer agrees to have all Underground Facilities, in the vicinity of
        the Equipment installation, clearly marked prior to the arrival of
        Rental Center's work crews. Customer assumes full responsibility for
        damage to all Underground Facilities. To identify Underground
        Facilities, Customer must call one week prior to installation.
      </p>

      <h3>NOTICE OF NON-WAIVER/SEVERABILITY</h3>
      <p>
        Any failure of Rental Center to insist upon strict performance by
        Customer as regards any provision of this Rental Agreement shall not be
        interpreted as a waiver of Rental Center's right to demand strict
        compliance with all other provisions of this Rental Agreement against
        Customer or any other person. The provisions of this Rental Agreement
        shall be severable so that the unenforceability, invalidity or waiver of
        any provision shall not effect any other provision.
      </p>

      <h3>COLLECTION COSTS</h3>
      <p>
        Customer agrees to pay all reasonable collection, attorney's and court
        fees and other expenses involved in the collection of the charges or
        enforcement of Dealer's rights under this contract.
      </p>
    </div>
  );
};

export default TermsConditions;
