import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import "./footer.css";

function Footer() {
  const { userDetails } = useAuth(); // Use the useAuth hook to get userDetails

  // Check if the user is 'super-admin' or 'admin'
  const isAdmin =
    userDetails &&
    (userDetails.role === "super-admin" || userDetails.role === "admin");

  return (
    <div
      className="your-element"
      style={{
        marginTop: "auto",
        background: "#627b7b",
        padding: "20px",
        display: "flex",
        justifyContent: "space-around",
        gap: "30px", // Control space between elements
        alignItems: "center",
        fontSize: "10px",
      }}
    >
      <p>*PROVISIONAL PATENT PENDING - CONFIDENTIAL AND PROPRIETARY</p>

      <Link to="/faq" style={{ color: "black", textDecoration: "none" }}>
        HELP
      </Link>

      <a
        href="https://www.fulcrumgroup.events/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black", textDecoration: "none" }}
        className="fulcrum-link"
      >
        FULCRUM GROUP
      </a>
      <a
        href="https://www.abbeyrentssf.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black", textDecoration: "none" }}
        className="abbey-link"
      >
        ABBEY PARTY RENTS
      </a>

      {isAdmin ? (
        <Link to="/admin" style={{ color: "black", textDecoration: "none" }}>
          PRIORUM STAFF
        </Link>
      ) : (
        <Link to="/request" style={{ color: "black", textDecoration: "none" }}>
          PRIORUM STAFF
        </Link>
      )}
    </div>
  );
}

export default Footer;
