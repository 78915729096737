import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DateSelection from "./pages/dateSelection";
import HomePage from "./pages/homePage";
import ItemPage from "./pages/itemPage/itemPage";
import AdminPage from "./pages/adminPage";
import OrderPage from "./pages/orderPage";
import OrderSummaryPage from "./pages/orderSummaryPage";
import HowItWorks from "./pages/howItWorks/howItWorks";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import ItemListPage from "./pages/itemListPage/ItemListPage";
import Category from "./components/categories/categories";
import { AuthProvider } from "./context/authContext";
import RequestAccess from "./pages/requestAccessPage/requestAccess";
import FAQ from "./pages/FAQ/faq";
import UserDashboard from "./pages/userDashboardPage/userDashboardPage";
import CategoryList from "./components/admin/categoryList";
import ItemList from "./components/admin/itemList";
import DownForMaintenance from "./pages/downForMaintenance/downForMaintenance";
import { ProtectedRoute } from "./components/protectedRoute";
import { Toaster } from "react-hot-toast";
import Footer from "./components/footer/footer";
import DateChecker from "./context/dateChecker";
import Header from "./components/header";
import CountdownTimer from "./context/countdownTimer";
import CartUpdateModal from "./components/cartUpdatedModal/cartUpdatedModal";
import CheckListPage from "./pages/checkListPage/checkListPage";
import { useLocation } from "react-router-dom";
import AdminLogin from "./components/Auth/adminLogIn";
import AdminSignUp from "./components/Auth/adminSignUp";
import UserLogin from "./components/Auth/UserLogin";
import UserSignUp from "./components/Auth/UserSignUp";
import "./App.css";
import PaymentPage from "./components/stripe/paymentPage";
import TermsConditions from "./components/termsConditions/termsConditions";

function AppContent() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === "/checklist";
  return (
    <>
      <Toaster />
      <div className="App">
        <AuthProvider>
          {!hideHeaderFooter && <Header />}
          <DateChecker />
          <div className="main-content">
            <CartUpdateModal />
            <CountdownTimer />
            <Routes>
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/signup" element={<AdminSignUp />} />
              <Route path="/user/login" element={<UserLogin />} />
              <Route path="/user/signup" element={<UserSignUp />} />
              <Route exact path="/checklist" element={<CheckListPage />} />
              <Route exact path="/request" element={<RequestAccess />} />
              <Route exact path="/howItWorks" element={<HowItWorks />} />
              <Route path="/user-dashboard" element={<UserDashboard />} />
              <Route path="/faq" element={<FAQ />} />
              <Route exact path="/" element={<DateSelection />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/home/:supercategoryId" element={<Category />} />
              <Route
                path="/home/supercategory/:supercategoryId"
                element={<ItemListPage />}
              />
              <Route
                path="/home/category/:categoryId"
                element={<ItemListPage />}
              />
              <Route path="/item/:id" element={<ItemPage />} />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute adminOnly={true}>
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/cart" element={<OrderPage />} />
              <Route path="/order-summary" element={<OrderSummaryPage />} />
              <Route
                path="/categories/:superCategoryId"
                element={<CategoryList />}
              />
              <Route
                path="/items/supercategory/:superCategoryId"
                element={<ItemList />}
              />
              <Route
                path="/items/category/:categoryId"
                element={<ItemList />}
              />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="termsConditions" element={<TermsConditions />} />
              <Route
                path="/downformaintenance"
                element={<DownForMaintenance />}
              />
            </Routes>
          </div>
          {!hideHeaderFooter && <Footer />}
        </AuthProvider>
      </div>
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <Router>
          <AppContent />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
