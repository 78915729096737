import React, { useState, useEffect } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import OpenTable from "../tables/openTable";
import ClosedTable from "../tables/closedTable";
import "./adminOrders.css";

const AdminOrders = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [totals, setTotals] = useState({ totalRetail: 0, totalDiscounts: 0 });

  const fetchTotals = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/orders/totals`);
      const data = await response.json();
      setTotals(data);
    } catch (error) {
      console.error("Error fetching totals:", error);
    }
  };

  useEffect(() => {
    fetchTotals();
  }, []);
  return (
    <div className="admin-orders-container">
      <Tabs.Root className="admin-tabs-container" defaultValue="open">
        <Tabs.List className="admin-tabs-list">
          <Tabs.Trigger className="admin-tab-trigger" value="open">
            Open Quotes
          </Tabs.Trigger>
          <Tabs.Trigger className="admin-tab-trigger" value="closed">
            Closed Quotes
          </Tabs.Trigger>
          <div className="admin-totals">
            <p>Total Retail: ${totals.totalRetail.toLocaleString()}</p>
            <p>Total Discounts: ${totals.totalDiscounts.toLocaleString()}</p>
          </div>
        </Tabs.List>
        <Tabs.Content value="open">
          <OpenTable fetchTotals={fetchTotals} />
        </Tabs.Content>
        <Tabs.Content value="closed">
          <ClosedTable fetchTotals={fetchTotals} />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
};

export default AdminOrders;
