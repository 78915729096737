import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateNotes } from "../../store/cartSlice";
import "./orderForm.css";
import cityData from "../../config/consolidated_city_zones.json";

export const OrderForm = ({ onSubmit, onCancel, initialValues }) => {
  const eventDate = useSelector((state) => state.eventDate); // Access the date from Redux
  const dispatch = useDispatch();
  const notesFromRedux = useSelector((state) => state.cart.notes);

  // Function to format the date from ISO 8601 to YYYY-MM-DD
  const formatDate = (dateString) => {
    return dateString.split("T")[0];
  };

  const [formData, setFormData] = useState({
    name: initialValues.name || "",
    email: initialValues.email || "",
    phone: initialValues.phone || "",
    dateOut: formatDate(eventDate.date),
    dateIn: formatDate(eventDate.date),
    notes: notesFromRedux,
    eventDate: formatDate(eventDate.date),
    type: "willCall", // Default order type to "willCall"
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    deliveryAddress: "", // Will be used when type is "delivery"
    deliveryCity: "",
    deliveryState: "",
    deliveryZip: "",
    isBillingSameAsDelivery: true, // Default set to true, meaning billing = delivery by default
  });

  // Filter city data to exclude "Other"
  const filteredCityData = cityData.filter((city) => city.City !== "Other");
  const [filteredCities, setFilteredCities] = useState(filteredCityData);
  const [selectedDeliveryCity, setSelectedDeliveryCity] = useState(null);

  useEffect(() => {
    // If the reduxDate changes and you want to update the form field:
    setFormData((prevFormData) => ({
      ...prevFormData,
      dateOut: formatDate(eventDate.date), // Update dateOut with the new reduxDate
      dateIn: formatDate(eventDate.date),
    }));
  }, [eventDate]); // Re-run effect if reduxDate changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Dispatch updateNotes action when notes change
    if (name === "notes") {
      dispatch(updateNotes(value));
    }

    if (name === "deliveryCity") {
      const lowerValue = value.toLowerCase();
      setFilteredCities(
        filteredCityData.filter((city) =>
          city.City.toLowerCase().includes(lowerValue)
        )
      );

      // Automatically select the city if it matches
      const matchedCity = filteredCityData.find(
        (city) => city.City.toLowerCase() === lowerValue
      );
      if (name === "deliveryCity") {
        if (matchedCity) {
          setSelectedDeliveryCity(matchedCity);
        } else {
          setSelectedDeliveryCity({
            City: value,
            Zone: "Other",
            DeliveryPrice: 1200,
          });
        }
      }
    }
  };

  const handleOrderTypeChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      type: value,
      // Reset addresses and billing check when order type changes
      billingAddress: "",
      deliveryAddress: "",
      isBillingSameAsDelivery: value === "delivery", // Default to true for delivery
    }));
  };

  const handleBillingSameAsDeliveryChange = (e) => {
    const { checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      isBillingSameAsDelivery: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Concatenate the delivery address fields if the order type is 'delivery' and not same as billing
    let deliveryAddress = "";
    let billingAddress = "";
    if (formData.type === "delivery") {
      deliveryAddress = `${formData.deliveryAddress}, ${formData.deliveryCity}, ${formData.deliveryState} ${formData.deliveryZip}`;
      if (formData.isBillingSameAsDelivery) {
        billingAddress = deliveryAddress; // If delivery is same as billing
      } else {
        billingAddress = `${formData.billingAddress}, ${formData.billingCity}, ${formData.billingState} ${formData.billingZip}`;
      }
    } else {
      billingAddress = `${formData.billingAddress}, ${formData.billingCity}, ${formData.billingState} ${formData.billingZip}`;
    }

    // Prepare the formData to be passed to the parent component
    const updatedFormData = {
      ...formData,
      billingAddress, // Update with concatenated billing address
      deliveryAddress, // Update with concatenated delivery address if applicable
    };

    onSubmit(updatedFormData); // Pass the updated formData to the parent component
  };

  const US_STATES = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
  ];

  return (
    <div className="order-form-modal">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        {/* Order Type Selection */}
        <div>
          <label>Select Order Type</label>
          <select
            name="type"
            value={formData.type}
            onChange={handleOrderTypeChange}
            required
          >
            <option value="willCall">
              Will Call (411 Allan Street, Daly City)
            </option>
            <option value="delivery">Delivery</option>
          </select>
        </div>

        {formData.type === "willCall" && (
          <>
            <div className="date-input-container">
              <div>
                <label htmlFor="dateOut">Pick Up Date</label>
                <input
                  type="date"
                  id="dateOut"
                  name="dateOut"
                  value={formData.dateOut}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="dateIn">Drop Off Date</label>
                <input
                  type="date"
                  id="dateIn"
                  name="dateIn"
                  value={formData.dateIn}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="billingAddress">Billing Address</label>
              <input
                type="text"
                id="billingAddress"
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
                required
              />
            </div>

            <div className="multi-input-container">
              <div>
                <label htmlFor="billingCity">City</label>
                <input
                  type="text"
                  id="billingCity"
                  name="billingCity"
                  value={formData.billingCity}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="billingState">State</label>
                <select
                  id="billingState"
                  name="billingState"
                  value={formData.billingState}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a State</option>
                  {US_STATES.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="billingZip">Zip Code</label>
                <input
                  type="text"
                  id="billingZip"
                  name="billingZip"
                  value={formData.billingZip}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </>
        )}

        {/* Conditional Billing and Delivery Address */}
        {formData.type === "delivery" && (
          <>
            <div className="date-input-container">
              <div>
                <label htmlFor="dateOut">Delivery Date</label>
                <input
                  type="date"
                  id="dateOut"
                  name="dateOut"
                  value={formData.dateOut}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="dateIn">Pick Up Date</label>
                <input
                  type="date"
                  id="dateIn"
                  name="dateIn"
                  value={formData.dateIn}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="deliveryAddress">Delivery Address</label>
              <input
                type="text"
                id="deliveryAddress"
                name="deliveryAddress"
                value={formData.deliveryAddress}
                onChange={handleChange}
                required
              />
            </div>

            <div className="multi-input-container">
              <div>
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="deliveryCity"
                  name="deliveryCity"
                  value={formData.deliveryCity}
                  onChange={handleChange}
                  required
                />
                <datalist id="city-list">
                  {filteredCities.map((city, index) => (
                    <option key={index} value={city.City}>
                      {city.City}
                    </option>
                  ))}
                </datalist>
                {selectedDeliveryCity && (
                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Delivery starts at: ${selectedDeliveryCity.DeliveryPrice}
                  </p>
                )}
              </div>
              <div>
                <label htmlFor="state">State</label>
                <select
                  id="deliveryState"
                  name="deliveryState"
                  value={formData.deliveryState}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a State</option>
                  {US_STATES.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="zip">Zip Code</label>
                <input
                  type="text"
                  id="deliveryZip"
                  name="deliveryZip"
                  value={formData.deliveryZip}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="isBillingSameAsDelivery">
                Is Billing Address the Same as Delivery?
              </label>
              <input
                type="checkbox"
                id="isBillingSameAsDelivery"
                name="isBillingSameAsDelivery"
                checked={formData.isBillingSameAsDelivery}
                onChange={handleBillingSameAsDeliveryChange}
              />
            </div>

            {/* Billing Address - Render only if not the same as Delivery */}
            {!formData.isBillingSameAsDelivery && (
              <>
                <div>
                  <label htmlFor="billingAddress">Billing Address</label>
                  <input
                    type="text"
                    id="billingAddress"
                    name="billingAddress"
                    value={formData.billingAddress}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="multi-input-container">
                  <div>
                    <label htmlFor="billingCity">City</label>
                    <input
                      type="text"
                      id="billingCity"
                      name="billingCity"
                      value={formData.billingCity}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="billingState">State</label>
                    <select
                      id="billingState"
                      name="billingState"
                      value={formData.billingState}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a State</option>
                      {US_STATES.map((state) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="billingZip">Zip Code</label>
                    <input
                      type="text"
                      id="billingZip"
                      name="billingZip"
                      value={formData.billingZip}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </>
            )}
            <p style={{ fontSize: "0.9rem" }}>
              * IF DELIVERY AND PICK UP ADDRESS IS DIFFERENT INDICATE THAT IN
              NOTES SECTION
            </p>
          </>
        )}
        <div>
          <p style={{ fontSize: "0.9rem" }}>
            * IF TENTING OR PRODUCTION IS NEEDED INDICATE THAT IN NOTES SECTION
          </p>
        </div>
        <div>
          <label htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>
        <div className="order-form-actions">
          <button type="submit" className="submit-order-btn">
            CONTINUE TO PAYMENT
          </button>
        </div>
      </form>
    </div>
  );
};
