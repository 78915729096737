import React from "react";
import { useNavigate } from "react-router-dom";
import "./howItWorks.css";

function HowItWorks() {
  const navigate = useNavigate();
  const imageUrl = `${process.env.REACT_APP_BLOB_IMAGE_URL}/HowitWorksPage.jpg`;
  return (
    <div className="howItWorksContainer">
      <h1>HOW IT WORKS</h1>
      <ol className="centeredList">
        <li>Choose the Date of Your Event</li>
        <li>Select Your Items and Desired Quantity</li>
        <li>Add Items to Cart</li>
        <li>Click on Cart to Check-Out</li>
        <li>
          Submit Quote Request, and Wait for Order Confirmation and Payment Link
        </li>
      </ol>
      <button onClick={() => navigate("/")}>BUILD MY EVENT</button>
      <img src={imageUrl} alt="How It Works" className="how-it-works-image" />
    </div>
  );
}

export default HowItWorks;
