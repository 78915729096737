import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import toast from "react-hot-toast";
import "./authStyles.css";
import { Link } from "react-router-dom";
import axios from "axios";

const UserSignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Send user details to MongoDB API
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/create`,
        {
          name,
          email,
          role: "user", // Default role
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Create user in Firebase Auth
      await createUserWithEmailAndPassword(auth, email, password);

      toast.success("Sign up successful!");
      navigate("/");
    } catch (error) {
      console.error("Sign up error:", error);
      toast.error(error.response?.data?.message || "Failed to sign up.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase()); // Convert email to lowercase
  };

  return (
    <div className="auth-container">
      <h2 className="auth-title">Welcome</h2>
      <p>Create your account</p>
      <form onSubmit={handleSignUp} className="auth-form">
        <input
          type="text"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="auth-input"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange} // Use the handleEmailChange function
          required
          className="auth-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="auth-input"
        />
        <button type="submit" className="auth-button">
          SIGN UP
        </button>
        <p>
          Already have an account?{" "}
          <Link
            to="/user/login"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            Log In
          </Link>
        </p>
      </form>
    </div>
  );
};

export default UserSignUp;
