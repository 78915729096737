import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import toast from "react-hot-toast";
import "./paymentPage.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function PaymentPage() {
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState(null); // For storing the client secret
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY); // Your Stripe public key
  const { state } = useLocation();
  const orderDetails = state ? state.orderDetails : null; // Check if state exists
  const depositAmount = orderDetails ? orderDetails.totalRentalPrice * 0.5 : 0;

  // Fetch client secret for payment intent
  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/orders/create-payment-intent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: depositAmount * 100, // 50% deposit
            }),
          }
        );
        const data = await response.json();

        if (response.ok) {
          setPaymentIntentClientSecret(data.clientSecret);
        } else {
          toast.error("Failed to create payment intent.");
        }
      } catch (error) {
        toast.error("Error creating payment intent.");
        console.error("Error:", error);
      }
    };

    fetchClientSecret();
    // eslint-disable-next-line
  }, [orderDetails]);

  return (
    <div className="payment-page-container">
      <div className="payment-form">
        <h2>Deposit Payment</h2>
        <div className="payment-summary">
          <p>Total Rental Price: ${orderDetails.totalRentalPrice.toFixed(2)}</p>
          <p style={{ fontWeight: "bold" }}>
            50% Deposit: ${depositAmount.toFixed(2)}
          </p>
        </div>
        {paymentIntentClientSecret && stripePromise && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: paymentIntentClientSecret,
            }}
          >
            <CheckoutForm
              paymentIntentClientSecret={paymentIntentClientSecret}
              orderDetails={orderDetails}
            />
          </Elements>
        )}
      </div>
      <p>
        By clicking pay deposit now I agree to the{" "}
        <Link
          to="/termsConditions"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          Terms and Conditions
        </Link>{" "}
        and understand my deposit is non-refundable
      </p>
    </div>
  );
}
